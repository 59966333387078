import { PropsWithChildren } from 'react';
import { FeatureTogglesContextProvider } from './contexts/FeatureTogglesContext';
import { IdentityContextProvider } from './contexts/IdentityContext';
import { GoogleAnalyticsContextProvider } from './contexts/GoogleAnalyticsContext';
import { InvitationContextProvider } from './contexts/InvitationContext';
import { AccountContextProvider } from './contexts/AccountContext';
import { LicenseTransferContextProvider } from './contexts/LicenseTransferContext';
import { UserImagesContextProvider } from './contexts/UserImagesContext';
import { MyAssignmentsContextProvider } from './contexts/MyAssignmentsContext';
import { AssetBasedTranslationContextProvider, SupportedUserLanguage } from './contexts/TranslationContext';
import Cookies from './utils/Cookies';
import { SchoolYearProvider } from '@pelckmans/business-components/context/SchoolYearContext';

export default function AppDataProvider({ children }: PropsWithChildren) {
  const { REACT_APP_P_STUDIO_GROUP, REACT_APP_P_STUDIO_LANGUAGE } = process.env;
  const lang = (Cookies.get('p-locale') as SupportedUserLanguage) || REACT_APP_P_STUDIO_LANGUAGE;

  return (
    <AssetBasedTranslationContextProvider lang={lang}>
      <FeatureTogglesContextProvider studioGroup={REACT_APP_P_STUDIO_GROUP}>
        <GoogleAnalyticsContextProvider>
          <IdentityContextProvider>
            <AccountContextProvider>
              <InvitationContextProvider>
                <LicenseTransferContextProvider>
                  <UserImagesContextProvider>
                    <MyAssignmentsContextProvider>
                      <SchoolYearProvider studioGroup={REACT_APP_P_STUDIO_GROUP}>{children}</SchoolYearProvider>
                    </MyAssignmentsContextProvider>
                  </UserImagesContextProvider>
                </LicenseTransferContextProvider>
              </InvitationContextProvider>
            </AccountContextProvider>
          </IdentityContextProvider>
        </GoogleAnalyticsContextProvider>
      </FeatureTogglesContextProvider>
    </AssetBasedTranslationContextProvider>
  );
}
